import React              from 'react'
import Helmet             from 'react-helmet'
import { Link }           from 'gatsby'

import config             from '../../../data/site-config'

import Layout             from '../../components/layout'
import MainWrapper        from '../../components/MainWrapper/MainWrapper'
import ServicePageHero    from '../../components/Services/ServicePageHero'
import ServicesPagination from '../../components/Services/ServicesPagination'

const StrategyRetainerPage = () => (
  <Layout className="service-page content-page" style={{backgroundColor: '#5a62f'}}>

    <Helmet
      title={`🎯 Strategy Retainer • Strategic and Tactical Consulting`}
      meta={[
        { name: 'description', content: 'Spacetime offers Strategy Retainer to clients; we act as an extension of your team, providing direction, insight, and creativity to your business.' }
      ]}
      link={[
        { rel: 'canonical', href: `${config.siteUrl}/services/strategy-retainer/` }
      ]}
      bodyAttributes={{
        class: 'light-theme'
      }}
    />

    <div>

      <ServicePageHero
        name={`strategy-retainer`}
        title={`Strategy Retainer`}
        description={`strategic and tactical expertise for growth hacking`}
        icon={`🎯`}
        iconPath={``}
        bgColor={`#5A62FF`}
        textColor={`white`} />

      <MainWrapper>
        <section className="pv6">
          <div className="row narrow">

            <p>Our consulting engagement will give you access to the strategic and tactical expertise you need, when you need it.</p>

            <h3>How does it work?</h3>

            <p>Our agreement entitles you to unlimited 24/7 access to <Link to="/team/">our team</Link> via phone, email, and IM. All messages will be returned no later than the next business day, although in practice response time is much quicker.</p>

            <p>Meetings are scheduled in advance, typically take place over the phone, and are sometimes supplemented with online meeting and/or screen sharing software (e.g. Hangouts, Skype, Slack).</p>

            <p>There are 7 possible areas of involvement:</p>

            <ol className="lh-copy">
              <li className="mv2">Participation in an initial in-depth, in-person meeting to define long-term business goals, existing legacy system architecture, design systems, product definition, and organizational challenges.</li>
              <li className="mv2">Regular meetings with the primary contact to discuss strategy, longer-term issues, and business goals.</li>
              <li className="mv2">Strategic and tactical liaison between you and contract workers and agencies. We can also act as a sounding board to ensure proper technical and strategic execution.</li>
              <li className="mv2">Technical review of APIs, IDEs, source code, documentation, interviews, and the like. Note: authoring production code, designs, or documentation is not included in this agreement.</li>
              <li className="mv2">Creative review of design concepts, user flows, wireframes, prototypes, design systems, interviews and the like.</li>
              <li className="mv2">Periodic competitive analysis and market insight and strategic response.</li>
              <li className="mv2">Situational responsiveness to needs that arise that you deem require our expertise and intervention.</li>
            </ol>

            <p>The fee is $8,500 USD per month, all inclusive (i.e. we pay for any necessary travel, administrative expenses, etc). Professional courtesy discounts are available for quarterly and annual arrangements. Participation is limited. You can elect to be added to the waiting list if spots are not available.</p>

            <p>Please contact for more information.</p>
          </div>
        </section>

        <section className="row pv4 pv5-ns mv5 bg-washed-yellow">
          <div className="tc">
            <h3 className="f2">Strategic expertise&mdash;<span style={{color: '#5a62ff'}}>Guaranteed Confidence</span>.</h3>
            <Link
              to="/contact/"
              className="ttu f7 fw8 tc link br1 ph5 pv2 pv3-ns dib mv4 dim white br-pill"
              style={{backgroundColor: '#5a62ff'}}>
                Contact us →
            </Link>
          </div>
        </section>

        <ServicesPagination
          link={`coffee-time`}
          name={`Coffee Time`}
          tagline={`business strategy and insight over a cup of coffee`}
          color={`#c0b282`}
        />

      </MainWrapper>
    </div>
  </Layout>
)

export default StrategyRetainerPage
